// extracted by mini-css-extract-plugin
export var cardOverride = "virtual-freezers-module--cardOverride--y+vQE";
export var cardWrapper = "virtual-freezers-module--cardWrapper--1KcHW";
export var headerOverride = "virtual-freezers-module--headerOverride--uT2Zj";
export var integrated_bg = "virtual-freezers-module--integrated_bg--+c7-t";
export var itemVariantOverride = "virtual-freezers-module--itemVariantOverride--WQerG";
export var joinInstitutions = "virtual-freezers-module--joinInstitutions--c+kp4";
export var joinInstitutionsContainerWrapper = "virtual-freezers-module--joinInstitutionsContainerWrapper--MWvFg";
export var joinInstitutionsWrapper = "virtual-freezers-module--joinInstitutionsWrapper--iHrl-";
export var labConsumablesWrapper = "virtual-freezers-module--labConsumablesWrapper--K9E5A";
export var layout = "virtual-freezers-module--layout--WwZVq";
export var limitWidthOnLargeScreens = "virtual-freezers-module--limitWidthOnLargeScreens--1gpiV";
export var link = "virtual-freezers-module--link--eeEK5";
export var listClassNameOverride = "virtual-freezers-module--listClassNameOverride--tVkRl";
export var margins = "virtual-freezers-module--margins--PjPx9";
export var titleOverride = "virtual-freezers-module--titleOverride--uwB3h";